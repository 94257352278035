import { Button, ButtonProps } from "@mui/material"
import { schemas } from "../generated/api/client"
import { z } from "zod"
import { apiClientHooks } from "../bootstrapping/InitApiClient"
import { queryClient } from "../bootstrapping/InitReactQuery"
import { useNavigate } from "react-router-dom"

type EBulkApplication = z.infer<typeof schemas.EBulkApplication>

const ApplicationNeedsChangesButton: React.FC<{ dbsApp: EBulkApplication } & Omit<ButtonProps, 'onclick'>> = ({ dbsApp, children, ...buttonProps }) => {
  const navigate = useNavigate()

  const requireChangesForEBulkApplicationByID = apiClientHooks.useRequireChangesForEBulkApplicationByID({ params: { id: dbsApp.id } })

  const onClickButton = async () => {
    await requireChangesForEBulkApplicationByID.mutateAsync(undefined)
    await queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
    await queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
    navigate(-1)
  }
  
  const applicableStatuses: Array<typeof dbsApp.status> = [
    'awaiting_countersigning',
  ]
  return applicableStatuses.includes(dbsApp.status)
    ? <Button {...buttonProps} onClick={onClickButton}>{children}</Button>
    : null
}

export default ApplicationNeedsChangesButton
