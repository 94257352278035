import React, { useEffect, useMemo, useState } from 'react';
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from 'material-react-table';
import { apiClientHooks } from "../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { z } from 'zod';
import { schemas } from '../generated/api/client';
import { Box, Button, ButtonProps, Chip, Container, IconButton, Modal, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextFieldElement } from 'react-hook-form-mui';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ClearIcon from '@mui/icons-material/Clear';
import HelpIcon from '@mui/icons-material/Help';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { queryClient } from '../bootstrapping/InitReactQuery';
import InvoiceIconButtonWithTooltip from '../components/InvoiceIconButtonWithTooltip';

export const Element: React.FC = () => {
  const getUninvoicedApplications = apiClientHooks.useGetUninvoicedApplications()
  const applicationIds = getUninvoicedApplications.data?.uninvoicedApplicationIds ?? []

  const getEBulkApplications = apiClientHooks.useGetEBulkApplications({
    queries: {
      columnFilters: [
        { id: 'id', value: applicationIds, },
      ],
      page_index: '0',
      page_size: applicationIds.length.toString(),
    }
  })

  const columnHelper = createMRTColumnHelper<EBulkApplication>();

  const columns = [
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('profile_given_name', {
      header: 'Given Name',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor('profile_family_name', {
      header: 'Family Name',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor(originalRow => originalRow.data.CurrentAddress?.Address.Postcode, {
      header: 'Postcode',
      size: 40,
      enableColumnFilter: true,
    }),
    columnHelper.accessor(originalRow => originalRow.data.DateOfBirth === undefined ? undefined : new Date(originalRow.data.DateOfBirth), {
      header: 'Date of Birth',
      size: 40,
      enableColumnFilter: false,
      Cell: ({ cell }) => cell.getValue<Date | undefined>()?.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })
    }),
    columnHelper.accessor(originalRow => originalRow.job_application_type === 'standard' ? 'true' : 'false', {
      header: 'Standard',
      size: 40,
      filterVariant: 'checkbox',
      muiFilterCheckboxProps: {
        title: '', // hide the label
      },
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.job_application_type === 'enhanced' ? 'true' : 'false', {
      header: 'Enhanced',
      size: 40,
      filterVariant: 'checkbox',
      muiFilterCheckboxProps: {
        title: '', // hide the label
      },
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.documents_customer?.application?.withAdultFirst === true ? 'true' : 'false', {
      header: 'ISA',
      size: 40,
      muiFilterCheckboxProps: {
        title: '', // hide the label
      },
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.job_volunteer === true ? 'true' : 'false', {
      header: 'Volunteer',
      size: 40,
      filterVariant: 'checkbox',
      muiFilterCheckboxProps: {
        title: '', // hide the label
      },
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor('payment_method', {
      header: 'Paid By',
      size: 40,
      filterVariant: 'multi-select',
      filterSelectOptions: [
        { value: 'invoice', label: 'Invoice' },
        { value: 'stripe', label: 'Stripe' },
        { value: 'paypal', label: 'PayPal' },
        { value: 'none', label: 'None' },
      ],
      Cell: ({ cell }) => {
        switch (cell.getValue()) {
          case 'invoice': return <Chip icon={<ReceiptIcon />} label="Invoice" color="error" sx={{ paddingLeft: 1, paddingRight: 1 }} />;
          case 'stripe': return <Chip icon={<CreditCardIcon />} label="Stripe" color="success" sx={{ paddingLeft: 1, paddingRight: 1 }} />;
          case 'paypal': return <Chip icon={<CreditCardIcon />} label="PayPal" color="success" sx={{ paddingLeft: 1, paddingRight: 1 }} />;
          case 'none': return <Chip icon={<ClearIcon />} label="None" color="warning" sx={{ paddingLeft: 1, paddingRight: 1 }} />;
          default: return <Chip icon={<HelpIcon />} label="Unknown" color="warning" sx={{ paddingLeft: 1, paddingRight: 1 }} />;
        }
      },
    }),
    columnHelper.accessor('payment_pence', {
      header: 'Amount',
      size: 120,
      filterVariant: 'range-slider',
      muiFilterSliderProps: {
        valueLabelFormat: (value) => (value / 100).toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }),
      },
      Cell: ({ cell }) => {
        const value = cell.getValue()
        if (typeof value !== 'number') return null
        return (value / 100).toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
        })
      },
    }),
  ];

  const table = useMaterialReactTable({
    data: getEBulkApplications.data?.rows ?? [],
    getRowId: row => row.id,
    columns,
    enableRowSelection: true,
    enableRowActions: true,
    renderRowActions: ({ table, row }) =>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <InvoiceIconButtonWithTooltip
          title="Invoice"
          icon={AttachMoneyIcon}
          disabled={!row.getIsSelected()}     
          selectedApplicationIds={
            table.getSelectedRowModel().rows.map(row => row.original.id)
          }
          doClearRowSelection={() => {
            table.setRowSelection({})
          }}
        />
      </Box>,
    // TODO: Add filters for column searching / ordering
    // next this
    paginationDisplayMode: 'pages',
  });

  return <>
    <Helmet>
      <title>Invoicing</title>
    </Helmet>
    <Container component="main" maxWidth={false}>
      <Typography component="h1" variant="h3" marginTop={3} marginBottom={3}>Invoicing</Typography>
      <Typography component="body" variant="body1" marginBottom={3}>
        Here is a list of applications that have not yet had an external invoice raised for them. Once you create each invoice, select the applications included on it and click the <AttachMoneyIcon /> invoice button, where you'll enter the external invoice reference number and it will drop from this list. If you need to, you can see the external invoice reference number in the applications list screen.
      </Typography>
      <MaterialReactTable table={table} />
    </Container>
  </>
}

export const NewInvoiceBatchButton: React.FC<ButtonProps> = ({ ...buttonProps }) => {
  const getUninvoicedApplications = apiClientHooks.useGetUninvoicedApplications()
  const applicationIds = getUninvoicedApplications.data?.uninvoicedApplicationIds ?? []
  const uninvoicedApplicationsCount = applicationIds.length

  const [selectedApplicationIds, setSelectedApplicationIds] = useState<string[]>([])

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    height: '95%',
    overflow: 'scroll',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const defaultPrefix = useMemo(() => {
    const date = new Date()
    const padToTwoDigits = (num: number) => num.toString().padStart(2, '0');
    return `INV-${date.getFullYear()}-${padToTwoDigits(date.getMonth() + 1)}-${padToTwoDigits(date.getDate())}-`
  }, [])

  const zLocalFormScheman = z.object({
    // Must end with -
    prefix: z.string().regex(/.*-$/, 'Must end with -'),
  })
  type LocalFormScheman = z.infer<typeof zLocalFormScheman>
  const form = useForm<LocalFormScheman>({
    resolver: zodResolver(zLocalFormScheman),
    mode: 'onChange',
  })


  return (
    <div>
      <Button
        {...buttonProps}
        onClick={handleOpen}
      >
        Create new Invoice Batch ({uninvoicedApplicationsCount} applications)
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            New Invoice Batch
          </Typography>
          <InvoicedApplicationTable applicationIds={applicationIds} setSelectedApplicationIds={setSelectedApplicationIds} />
          <Typography component="body" variant="body1" sx={{ mb: 2 }}>
            Please enter the prefix for each of the payment references.
          </Typography>
          <TextFieldElement
            control={form.control}
            name="prefix"
            label="Invoice Reference Prefix"
            placeholder={defaultPrefix}
            sx={{ mb: 1 }}
            fullWidth
          />
          <Typography component="body" variant="body2" sx={{ mb: 2 }}>
            e.g. <em>INV-2021-09-01-</em> would result in <em>INV-2021-09-01-001</em>,  <em>INV-2021-09-01-002</em>,  <em>INV-2021-09-01-003</em> etc.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={!form.formState.isValid || selectedApplicationIds.length === 0}
            onClick={() => {
              form.handleSubmit(async (data) => {
                // TODO: Update the API to accept update each application with the new invoice reference & payment date (based on the prefix) -- do it all in a transaction
                // await apiClient.createInvoiceBatch({
                //   payment_reference_prefix: data.prefix,
                //   application_ids: selectedApplicationIds,
                // })
                // queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getInvoiceBatches') })
                queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getUninvoicedApplications') })
                handleClose()
              })()
              handleClose()
            }}
          >
            Mark {selectedApplicationIds.length} Selected Applications as Paid
          </Button>
        </Box>
      </Modal>
    </div>
  )
}

type EBulkApplicationsWithMeta = z.infer<typeof schemas.ReturnType_GetEBulkApplications>
export type EBulkApplication = EBulkApplicationsWithMeta['rows'][number]

// TODO: Add button to export to PDF (which exports all the rows, like in the demo)
export const InvoicedApplicationTable: React.FC<{ applicationIds: string[], setSelectedApplicationIds?: React.Dispatch<React.SetStateAction<string[]>> }> = ({ applicationIds, setSelectedApplicationIds }) => {
  const getEBulkApplications = apiClientHooks.useGetEBulkApplications({
    queries: {
      columnFilters: [
        { id: 'id', value: applicationIds, },
      ],
      page_index: '0',
      page_size: applicationIds.length.toString(),
    }
  })

  const columnHelper = createMRTColumnHelper<EBulkApplication>();

  const columns = [
    columnHelper.accessor('customer_name', {
      header: 'Customer Name',
      size: 40,
      enableColumnFilter: true,      
    }),
    columnHelper.accessor('profile_given_name', {
      header: 'Given Name',
      size: 40,
      enableColumnFilter: true,      
    }),
    columnHelper.accessor('profile_family_name', {
      header: 'Family Name',
      size: 40,
      enableColumnFilter: true,      
    }),
    columnHelper.accessor(originalRow => originalRow.data.CurrentAddress?.Address.Postcode, {
      header: 'Postcode',
      size: 40,
      enableColumnFilter: true,      
    }),
    columnHelper.accessor(originalRow => originalRow.data.DateOfBirth === undefined ? undefined : new Date(originalRow.data.DateOfBirth), {
      header: 'Date of Birth',
      size: 40,
      filterVariant: 'date-range',
      Cell: ({ cell }) => cell.getValue<Date | undefined>()?.toLocaleDateString()
    }),
    columnHelper.accessor(originalRow => originalRow.job_application_type === 'standard' ? 'true' : 'false', {
      header: 'STD',
      size: 40,
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.job_application_type === 'enhanced' ? 'true' : 'false', {
      header: 'ENH',
      size: 40,
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.documents_customer?.application?.withAdultFirst === true ? 'true' : 'false', {
      header: 'ISA',
      size: 40,
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor(originalRow => originalRow.job_volunteer === true ? 'true' : 'false', {
      header: 'Volunteer',
      size: 40,
      filterVariant: 'checkbox',
      Cell: ({ cell }) => cell.getValue() === 'true' ? '✅' : '❌',
    }),
    columnHelper.accessor('payment_pence', {
      header: 'Amount',
      size: 120,
      filterVariant: 'range-slider',
      muiFilterSliderProps: {
        valueLabelFormat: (value) => (value / 100).toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
        }),
      },
      Cell: ({ cell }) => {
        const value = cell.getValue()
        if (typeof value !== 'number') return null
        return (value / 100).toLocaleString('en-GB', {
          style: 'currency',
          currency: 'GBP',
        })
      },
    }),
  ];

  const table = useMaterialReactTable({
    data: getEBulkApplications.data?.rows ?? [],
    columns,
    enableRowSelection: setSelectedApplicationIds !== undefined,
    paginationDisplayMode: 'pages',
  });

  const selectedApplicationIds = table.getSelectedRowModel().rows.map(row => row.original.id)
  useEffect(() => {
    setSelectedApplicationIds && setSelectedApplicationIds(selectedApplicationIds)
  }, [setSelectedApplicationIds, selectedApplicationIds])

  return <MaterialReactTable table={table} />;
}
