import React, { useMemo } from 'react';
import {
  MRT_Row,
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import {
  QueryClient,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { ActionFunction, useNavigate } from "react-router-dom";
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { z } from 'zod';
import { schemas } from '../generated/api/client';
import { isSomething } from '../utils/utils';
import { useAppTable } from '../components/AppTable';
import { useIdTokenClaims } from '../components/auth0/useIdTokenClaims';
import { refetchProfileJwt } from '../bootstrapping/InitRequireProfile';
import IconButtonWithTooltip from '../components/IconButtonWithTooltip';

type InvitesWithMeta = z.infer<typeof schemas.ReturnType_GetInvites>
type Invite = InvitesWithMeta['rows'][number]

export const Element: React.FC = () => {
  const { email, idToken } = useIdTokenClaims()

  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const openDeleteConfirmModal = async (row: MRT_Row<Invite>) => {
    if (window.confirm('Are you sure you want to rescind this invite?')) {
      await apiClient.rescindInvite(undefined, { params: { id: row.original.id } });
      // Clear all the cached data for the getInvites query (regardless of filters, etc.)
      await queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getInvites') });
    }
  };

  const openAcceptConfirmModal = async (row: MRT_Row<Invite>) => {
    if (window.confirm('Are you sure you want to accept this invite?')) {
      const result = await apiClient.acceptInvite(undefined, {
        params: { id: row.original.id },
        headers: { 'x-auth0-id-token': idToken ?? '' },
      });

      if (result.accepted) {
        refetchProfileJwt()
      }

      // Clear all the cached data for the getInvites query (regardless of filters, etc.)
      await queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getInvites') });
    }
  };

  const {
    tableConfig,
  } = useAppTable<Invite, InvitesWithMeta, Error | null>({
    useTableQuery: ({ columnFilters, globalFilter, pagination, sorting }) =>
      useQuery<InvitesWithMeta>({
        queryKey: [
          ...apiClientHooks.getKeyByAlias('getInvites'),
          columnFilters, //refetch when columnFilters changes
          globalFilter ?? '', //refetch when globalFilter changes
          pagination.pageIndex, //refetch when pagination.pageIndex changes
          pagination.pageSize, //refetch when pagination.pageSize changes
          sorting, //refetch when sorting changes
        ],
        queryFn: async () => {
          // Build the sort by string, and ensure it meets the API schema
          const sort = (() => {
            const firstSort = sorting[0]
            if (!firstSort) return undefined

            const compoundSort = `${firstSort.id} ${firstSort.desc ? 'desc' : 'asc'}`
            return schemas.Sort_GetInvites.parse(compoundSort)
          })()

          return await apiClient.getInvites({
            queries: {
              page_index: `${pagination.pageIndex}`,
              page_size: `${pagination.pageSize}`,
              sort,
              globalFilter: globalFilter.length > 0 ? globalFilter : undefined,
              columnFilters: schemas.ColumnFilters_GetInvites.parse(
                columnFilters
                  .map(filter => {
                    // Check ID is a valid column
                    const parseResult = schemas.ColumnFilters_GetInvites.element.safeParse(filter)
                    if (!parseResult.success && parseResult.error.errors.find(e => e.path[0] === 'id')) {
                      console.error("Invalid column ID for filter: ", filter)
                      return undefined
                    }

                    // Transform Enum Filters
                    if (filter.id === 'type') {
                      if (!parseResult.success) {
                        console.error("Invalid enum filter: ", filter)
                        return undefined
                      }
                      if (parseResult.data.id !== 'type') {
                        console.error("Mismatched filter and parseResult: ", { filter, parseResult })
                        return undefined
                      }

                      return {
                        id: parseResult.data.id,
                        value: parseResult.data.value,
                      }
                    }

                    // Transform Boolean Filters
                    if (filter.id === 'active') {
                      const valueAsStringBoolean = z.union([z.literal('true'), z.literal('false')]).safeParse(filter.value)
                      if (!valueAsStringBoolean.success) {
                        console.error("Invalid Boolean Filter (value should be string 'true' or 'false'): ", filter)
                        return undefined
                      }

                      return {
                        id: filter.id,
                        value: valueAsStringBoolean.data === 'true' ? true : false,
                      }
                    }

                    // Transform Date Range Filters
                    if (filter.id === 'invited_at' || filter.id === 'accepted_at') {
                      const values = z.array(z.date().optional()).safeParse(filter.value)
                      if (!values.success || values.data.length !== 2) {
                        console.error("Invalid date range filter: ", filter)
                        return undefined
                      }
                      if (values.data[0] === undefined && values.data[1] === undefined) {
                        // Nothing to filter on!
                        return undefined
                      }

                      return {
                        id: filter.id,
                        from: values.data[0]?.toISOString(),
                        to: values.data[1]?.toISOString(),
                      }
                    }

                    // Everything else is just a text filter
                    var parsedValue = z.string().safeParse(filter.value)
                    if (!parsedValue.success) {
                      console.error("Invalid text filter: ", filter)
                      return undefined
                    }

                    return {
                      id: filter.id,
                      value: parsedValue.data,
                    }
                  })
                  .filter(isSomething)
              )
            },
          })
        },
        placeholderData: keepPreviousData, //don't go to 0 rows when refetching or paginating to next page
      }),
    columns: useMemo<MRT_ColumnDef<Invite>[]>(() => [
      {
        accessorKey: 'accepted_at',
        header: 'Accepted At',
        size: 150,
        enableSorting: true,
        filterVariant: 'datetime-range',
        muiFilterDateTimePickerProps: {
          format: 'dd-MMM-yyyy HH:mm',
        },
        _visible: false,
      },
      {
        accessorKey: 'accepted_by_profile_id',
        header: 'Accepted by Profile ID',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'accepted_by_profile_name',
        header: 'Accepted by Profile Name',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'type',
        header: 'Type',
        size: 150,
        enableSorting: true,
        filterVariant: 'select',
        filterSelectOptions: [
          { label: 'Staff', value: 'staff' },
          { label: 'Customer', value: 'customer' },
          { label: 'Applicant', value: 'applicant' },
        ],
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 200,
        enableSorting: true,
      },
      {
        accessorKey: 'customer_id',
        header: 'Customer ID',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'customer_name',
        header: 'Customer Name',
        size: 150,
        enableSorting: true,
      },
      {
        accessorKey: 'job_id',
        header: 'Job ID',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'job_role',
        header: 'Job Role',
        size: 150,
        enableSorting: true,
      },
      {
        accessorKey: 'test_application',
        accessorFn: (originalRow) => (
          originalRow.type === 'applicant'
            ? originalRow.test_application
              ? 'true'
              : 'false'
            : ''
        ), //must be strings
        header: 'Test Application',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) =>
          cell.getValue() === 'true'
            ? '✅'
            : cell.getValue() === 'false'
              ? '❌'
              : '',
      },
      {
        accessorKey: 'invited_at',
        header: 'Invited At',
        size: 150,
        enableSorting: true,
        filterVariant: 'datetime-range',
        muiFilterDateTimePickerProps: {
          format: 'dd-MMM-yyyy HH:mm',
        },
      },
      {
        accessorKey: 'invited_by_profile_id',
        header: 'Invited by Profile ID',
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        _visible: false,
      },
      {
        accessorKey: 'invited_by_profile_name',
        header: 'Invited by Profile Name',
        size: 150,
        enableSorting: true,
      },
    ], []),
    renderAdditionalTopToolbarCustomActions: () => (
      <Button variant="contained" onClick={() => { navigate("/invites/add") }}>
        New Invite
      </Button>
    ),
  });

  const table = useMaterialReactTable({
    ...tableConfig,
    enableRowActions: true,
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        {row.original.active === true
          ? (
            <IconButtonWithTooltip
              title="Rescind invite"
              icon={DeleteIcon}
              onClick={() => openDeleteConfirmModal(row)}
            />
          )
          : (
            <IconButtonWithTooltip
              title="Inactive invites cannot be rescinded"
              icon={DeleteIcon}
              disabled={true}
            />
          )}
        {row.original.active === false
          ? (
            <IconButtonWithTooltip
              title="Cannot accepted inactive invites"
              icon={CheckIcon}
              disabled={true}
            />
          )
          : row.original.email !== email
            ? (
              <IconButtonWithTooltip
                title={`Does not match logged in email: ${email}`}
                icon={CheckIcon}
                disabled={true}
              />
            )
            : (
              <IconButtonWithTooltip
                title="Accept invite"
                icon={CheckIcon}
                color='success'
                onClick={() => openAcceptConfirmModal(row)}
              />
            )}
      </Box>
    ),
  });

  return <>
    <Helmet>
      <title>Invites</title>
    </Helmet>
    <MaterialReactTable table={table} />;
  </>
}

export const rescind = (queryClient: QueryClient): ActionFunction => async ({ params, request, context }) => {
  console.log("Invites.rescind: ", { params, request, context })

  if (params.id === undefined) throw new Error("Missing Invite ID to rescind")

  await apiClient.rescindInvite(undefined, { params: { id: params.id } })
  await queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getInvites') }) // Don't wait for this to complete
  return null
}
