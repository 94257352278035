import React, { useMemo } from "react";
import { Container, Box, Typography, Grid, Card, CardContent, CardActions, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { apiClientHooks } from "../bootstrapping/InitApiClient";

export type DisplayCardProps = {
  title: string;
  count?: number;
  breakdown?: { title: string, count?: number, link?: string }[];
  linkList?: string;
  linkNew?: string;
}
export const DisplayCard: React.FC<DisplayCardProps> = props => {
  const navigate = useNavigate()

  const buttons = useMemo(() => {
    return <>
      {props.linkList && <Button size="small" onClick={() => props.linkList && navigate(props.linkList)}>📋 See All</Button>}
      {props.linkNew && <Button size="small" onClick={() => props.linkNew && navigate(props.linkNew)}>➕ Add New</Button>}
    </>
  }, [navigate, props.linkList, props.linkNew])

  return (
    <Card variant="outlined" sx={{ backgroundColor: '#e9e9e9' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="h5" component="div">
          {props.count ?? <>&nbsp;</> /* holding the visual space while data loads */}
        </Typography>
        <Stack sx={{ marginTop: 1 }}>
          {props.breakdown?.map((item, index) =>
            !!item.link
              ? <div key={index} style={{ cursor: 'pointer' }} onClick={() => item.link && navigate(item.link)}>{item.title}: <strong>{item.count}</strong></div>
              : <div key={index}>{item.title}: <strong>{item.count}</strong></div>
          )}
        </Stack>
      </CardContent>
      <CardActions>
        {buttons}
      </CardActions>
    </Card>
  )
}

export type DisplayPricingCardProps = {
  title: string;
}
export const DisplayPricingCard: React.FC<DisplayPricingCardProps> = props => {
  const getPricing = apiClientHooks.useGetPricing()

  return (
    <Card variant="outlined" sx={{ backgroundColor: '#e9e9e9' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.title}
        </Typography>
        <Stack sx={{ marginTop: 1 }}>
          {getPricing.data?.map(item =>
            <div key={item.code}>{item.label}: <strong>£{((item.priceInPence ?? 0) / 100).toFixed(2)}</strong></div>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}

export const Element: React.FC = () => {
  console.log("index.Element: ")

  const getGlobalStats = apiClientHooks.useGetGlobalStats()
  const stats = getGlobalStats.data?.stats

  const isTestingEnabled = process.env.REACT_APP_TESTING === 'true'

  return <>
    <Helmet>
      <title>Administration</title>
    </Helmet>
    <Container component="main">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" marginBottom={3}>
          Dashboard
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard title="Awaiting Countersigning" count={stats?.applications.totalAwaitingCountersigning} linkList="/applications?status=awaiting_countersigning" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard title="Ready to Invoice" count={stats?.applications.totalUninvoiced} linkList="/invoicing" />
          </Grid>
        </Grid>
        <Typography component="h1" variant="h5" marginBottom={3}>
          Administration Interface
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard title="👥 Profiles" linkList="/profiles" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard title="🫅 Customers" count={stats?.customers.totalActive} linkList="/customers" linkNew="/customers/add" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard title="🧑‍🏫 Job Roles" count={stats?.jobs.totalActive} linkList="/jobs" linkNew="/jobs/add" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard
              title="📨 Invites"
              count={stats === undefined ? undefined : stats.invites.totalActiveStaff + stats.invites.totalActiveCustomers + stats.invites.totalActiveApplicants}
              breakdown={[
                { title: "Staff", count: stats?.invites.totalActiveStaff },
                { title: "Customers", count: stats?.invites.totalActiveCustomers },
                { title: "Applicants", count: stats?.invites.totalActiveApplicants },
              ]}
              linkList="/invites"
              linkNew="/invites/add"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard
              title="📝 Applications"
              count={stats === undefined ? undefined : stats.applications.totalDraft + stats.applications.totalAwaitingCustomerApproval + stats.applications.totalNeedsChanges + stats.applications.totalAwaitingCountersigning + stats.applications.totalAwaitingDBSSubmission + stats.applications.totalAwaitingDBSResponse + stats.applications.totalRejected + stats.applications.totalRestarted + stats.applications.totalCompleted}
              breakdown={[
                { title: "Draft", count: stats?.applications.totalDraft, link: "/applications?status=draft" },
                { title: "Awaiting Customer Approval", count: stats?.applications.totalAwaitingCustomerApproval, link: "/applications?status=awaiting_customer_approval" },
                { title: "Awaiting Payment", count: stats?.applications.totalAwaitingPayment, link: "/applications?status=awaiting_payment" },
                { title: "Needs Changes", count: stats?.applications.totalNeedsChanges, link: "/applications?status=needs_changes" },
                { title: "Awaiting Countersigning", count: stats?.applications.totalAwaitingCountersigning, link: "/applications?status=awaiting_countersigning" },
                { title: "Awaiting DBS Submission", count: stats?.applications.totalAwaitingDBSSubmission, link: "/applications?status=awaiting_submission_to_DBS" },
                { title: "Awaiting DBS Response", count: stats?.applications.totalAwaitingDBSResponse, link: "/applications?status=awaiting_response_from_DBS" },
                { title: "Rejected", count: stats?.applications.totalRejected, link: "/applications?status=rejected" },
                { title: "Restarted", count: stats?.applications.totalRejected, link: "/applications?status=restarted" },
                { title: "Completed", count: stats?.applications.totalCompleted, link: "/applications?status=completed" },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DisplayCard
              title="🛜 DBS E-Bulk"
              // count={stats === undefined ? undefined : stats.applications.totalDraft + stats.applications.totalAwaitingCustomerApproval + stats.applications.totalAwaitingCountersigning + stats.applications.totalAwaitingDBSSubmission + stats.applications.totalAwaitingDBSResponse + stats.applications.totalRejected + stats.applications.totalCompleted}
              breakdown={[
                { title: "Awaiting DBS Submission", count: stats?.applications.totalAwaitingDBSSubmission, link: "/applications?status=awaiting_submission_to_DBS" },
                { title: "Awaiting DBS Response", count: stats?.applications.totalAwaitingDBSResponse, link: "/applications?status=awaiting_response_from_DBS" },
                { title: "Rejected", count: stats?.applications.totalRejected, link: "/applications?status=rejected" },
              ]}
              linkList="/ebulk"
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <DisplayPricingCard title="💰 Pricing" />
          </Grid>
          {isTestingEnabled && (
            <Grid item xs={12} sm={6} md={4}>
              <DisplayCard title="🚨🚨🚨 TESTING IS ENABLED 🚨🚨🚨" linkList="/testing" />
            </Grid>
          )}
        </Grid>
      </Box>
    </Container>
  </>
}