import { useMemo, useState } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UseQueryResult } from '@tanstack/react-query';
import { MRT_ColumnDef, MRT_ColumnFiltersState, MRT_PaginationState, MRT_SortingState, type MRT_RowData, type MRT_TableOptions } from 'material-react-table';

type QueryRow = MRT_RowData & {
  id: string;
  created_at: string;
  updated_at: string;
  active: boolean;
};
type QueryResult<QueryRow> = {
  rows: QueryRow[];
  meta: {
    totalRowCount: number;
  };
}

type UseAppTableProps<TRow extends QueryRow, TData extends QueryResult<TRow>, TError extends Error | null> = {
  columnFilterInitialValues?: (defaults: MRT_ColumnFiltersState) => MRT_ColumnFiltersState;
  useTableQuery: (
    props: {
      columnFilters: MRT_ColumnFiltersState,
      globalFilter: string,
      sorting: MRT_SortingState,
      pagination: MRT_PaginationState,
    }
  ) => UseQueryResult<TData, TError>;
  columns?: (MRT_ColumnDef<TRow> & { _visible?: boolean })[];
  renderAdditionalTopToolbarCustomActions?: MRT_TableOptions<TRow>['renderTopToolbarCustomActions'];
};
export const useAppTable = <TRow extends QueryRow, TData extends QueryResult<TRow>, TError extends Error | null>({ columnFilterInitialValues, useTableQuery, columns, renderAdditionalTopToolbarCustomActions }: UseAppTableProps<TRow, TData, TError>) => {
  const defaultedColumnFilterInitialValues = useMemo(() => columnFilterInitialValues ?? ((defaults: MRT_ColumnFiltersState) => defaults), [columnFilterInitialValues]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    defaultedColumnFilterInitialValues([
      { id: 'active', value: 'true' },
    ]),
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const queryResult = useTableQuery({
    columnFilters,
    globalFilter,
    sorting,
    pagination,
  });

  /**
   * We've added _visible to the MRT_ColumnDef type so we can control the initial visibility of columns.
   * We need to convert:
   * ```typescript
   * [
   *   { accessorKey: 'field_name_a', ..., _visible: false },
   *   { accessorKey: 'field_name_b', ... },
   *   { accessorKey: 'field_name_c', ..., _visible: true },
   * ]
   * ```
   * to:
   * ```typescript
   * {
   *  field_name_a: false,
   *  field_name_c: true,
   * }
   * ```
   * This will be used to set the initial column visibility state.
   */
  const hiddenColumns = useMemo((): { [key: string]: boolean } =>
    columns?.filter(column => column._visible != null)
      .reduce((acc, column) => ({ ...acc, [`${column.accessorKey}`]: column._visible }), {}) ?? []
    , [columns]);

  const defaultedColumns = useMemo<MRT_ColumnDef<TRow>[]>(() => [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 150,
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'created_at',
      header: 'Created At',
      size: 150,
      enableSorting: true,
      filterVariant: 'datetime-range',
      muiFilterDateTimePickerProps: {
        format: 'dd-MMM-yyyy HH:mm',
      },
    },
    {
      accessorKey: 'updated_at',
      header: 'Updated At',
      size: 150,
      enableSorting: true,
      filterVariant: 'datetime-range',
      muiFilterDateTimePickerProps: {
        format: 'dd-MMM-yyyy HH:mm',
      },
    },
    {
      accessorKey: 'active',
      accessorFn: (originalRow) => (originalRow.active ? 'true' : 'false'), //must be strings
      header: 'Active',
      size: 150,
      enableSorting: true,
      enableColumnFilter: true,
      Cell: ({ cell }) => cell.getValue() === 'true' ? 'Active' : 'Inactive',
      filterVariant: 'checkbox',
    },
    ...(columns ?? []),
  ], [columns]);

  const tableConfig = useMemo((): MRT_TableOptions<TRow> => ({
    columns: defaultedColumns,
    data: queryResult.data?.rows ?? [],
    getRowId: row => row.id,
    initialState: {
      showColumnFilters: true,
      columnVisibility: {
        id: false,
        created_at: false,
        updated_at: false,
        active: false,
        ...hiddenColumns,
      },
    },
    manualFiltering: true, //turn off built-in client-side filtering
    manualPagination: true, //turn off built-in client-side pagination
    manualSorting: true, //turn off built-in client-side sorting
    muiToolbarAlertBannerProps: queryResult.isError
      ? {
        color: 'error',
        children: !!queryResult.error?.message
          ? `Error loading data: ` + queryResult.error.message
          : 'Error loading data',
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    renderTopToolbarCustomActions: (props) => (
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <Tooltip arrow title="Refresh Data">
          <IconButton onClick={() => queryResult.refetch()}>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
        {renderAdditionalTopToolbarCustomActions && renderAdditionalTopToolbarCustomActions(props)}
      </Box>
    ),
    rowCount: queryResult.data?.meta?.totalRowCount ?? 0,
    state: {
      columnFilters,
      globalFilter,
      isLoading: queryResult.isLoading,
      pagination,
      showAlertBanner: queryResult.isError,
      showProgressBars: queryResult.isRefetching,
      sorting,
    },

  }), [columnFilters, globalFilter, pagination, sorting, setColumnFilters, setGlobalFilter, setPagination, setSorting, queryResult, hiddenColumns, defaultedColumns, renderAdditionalTopToolbarCustomActions]);

  return {
    tableConfig,
  };
}
