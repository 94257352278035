import React, { useState } from 'react';
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { z } from 'zod';
import { TextFieldElement, useForm } from 'react-hook-form-mui';
import { zodResolver } from '@hookform/resolvers/zod';
import { apiClient, apiClientHooks } from '../bootstrapping/InitApiClient';
import { queryClient } from '../bootstrapping/InitReactQuery';
import CloseIcon from '@mui/icons-material/Close';
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from './IconButtonWithTooltip';

type PDFExportTableButtonsProps = {
  selectedApplicationIds: string[],
  doClearRowSelection: () => void,
} & IconButtonWithTooltipProps

const InvoiceIconButtonWithTooltip: React.FC<PDFExportTableButtonsProps> = ({ selectedApplicationIds, doClearRowSelection, ...iconButtonWithTooltipProps }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const zLocalFormScheman = z.object({
    external_invoice_reference: z.string(),
  })
  type LocalFormScheman = z.infer<typeof zLocalFormScheman>
  const form = useForm<LocalFormScheman>({
    resolver: zodResolver(zLocalFormScheman),
    mode: 'onChange',
  })

  return (
    <div>
      <IconButtonWithTooltip
        {...iconButtonWithTooltipProps}
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton onClick={handleClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseIcon />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
            Invoice Applications
          </Typography>
          <Typography component="body" variant="body1" sx={{ mb: 2 }}>
            {selectedApplicationIds.length} applications are ready to invoice.
          </Typography>
          <TextFieldElement
            control={form.control}
            name="external_invoice_reference"
            label="External Invoice Reference"
            sx={{ mb: 1 }}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!form.formState.isValid}
            onClick={async () => {
              const { external_invoice_reference } = form.getValues()
              console.log("Invoice selected applications: ", selectedApplicationIds, " with external invoice reference: ", external_invoice_reference)
              
              await apiClient.markApplicationsAsInvoiced({
                external_invoice_reference,
                application_ids: selectedApplicationIds,
              })

              queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
              queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getUninvoicedApplications') })
              queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') })

              doClearRowSelection()
              handleClose()
            }}
          >
            Mark {selectedApplicationIds.length} Applications as Invoiced
          </Button>
        </Box>
      </Modal>
    </div>
  )
}

export default InvoiceIconButtonWithTooltip
