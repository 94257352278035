import { Box, Button, Modal, Typography, IconButton, ButtonProps } from "@mui/material"
import { Close as CloseIcon } from '@mui/icons-material'
import { schemas } from "../generated/api/client"
import { z } from "zod"
import { apiClient, apiClientHooks } from "../bootstrapping/InitApiClient"
import { queryClient } from "../bootstrapping/InitReactQuery"
import { useState } from "react"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import JobAutocomplete from "./JobAutocomplete"

type EBulkApplication = z.infer<typeof schemas.EBulkApplication>

const ChangeJobRoleForApplicationButton: React.FC<{ dbsApp: EBulkApplication } & Omit<ButtonProps, 'onclick'>> = ({ dbsApp, children, ...buttonProps }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const zLocalFormScheman = z.object({
    job_id: z.string(),
  })
  type LocalFormScheman = z.infer<typeof zLocalFormScheman>
  const form = useForm<LocalFormScheman>({
    resolver: zodResolver(zLocalFormScheman),
    mode: 'onBlur',
  })

  // Show the button disabled until we're ready for use
  if (dbsApp.customer_id === undefined) {
    return <Button {...buttonProps} onClick={handleOpen} disabled>{children}</Button>
  }

  return <>
    <Button {...buttonProps} onClick={handleOpen}>{children}</Button>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
          Change Job Role
        </Typography>
        <Typography id="modal-modal-description" sx={{ mb: 2 }}>
          An application can have it's job role changed at any time before countersigning.
        </Typography>
        <Typography id="modal-modal-description" sx={{ mb: 2 }}>
          This is most often done when the job role settings are incorrectly set for the role applied for.
        </Typography>
        <JobAutocomplete
          control={form.control}
          customerId={dbsApp.customer_id}
          name="job_id"
          label="Job Role"
          fullWidth
        />
        <Button
          variant="contained"
          onClick={() => {
            form.handleSubmit(async (data) => {
              await apiClient.changeJobRoleForEBulkApplicationByID(undefined, {
                params: { id: dbsApp.id },
                queries: { jobId: data.job_id },
              })
              queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
              queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
              queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('priceForEBulkApplicationByID') })
              handleClose()
            })()
          }}
          sx={{ mt: 2 }}
        >
          Change Job Role
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={handleClose}
          sx={{ ml: 3, mt: 2 }}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  </>
}

export default ChangeJobRoleForApplicationButton
